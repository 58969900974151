
function HomePage(){

    return (
        <div>
            <h1>Home - Teste Deploy</h1>
        </div>
    )
}

export default HomePage;