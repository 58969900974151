import './App.css';
import RoutesModule from './pages/routes';

function App() {
  return (
    <RoutesModule/>
  );
}

export default App;
