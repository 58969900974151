
function GrupoNewPage(){

    return (
        <div>
            <p>New</p>
        </div>
    )
}

export default GrupoNewPage;